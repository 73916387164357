import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {Col, Container, Row} from "react-bootstrap";
import {FaQuestionCircle} from "react-icons/fa";

const SecondPage = () => (
    <Layout pageInfo={{pageName: "about"}}>
        <SEO title="About Massages by Jooley Portland Massage Therapy"/>

        <Container>
            <Row>
                <Col lg={true}>
                    <h1><FaQuestionCircle/> About Jooley and her Journey as a Healer</h1>
                    <hr/>
                </Col>
            </Row>
        </Container>

        <Container>
            <Row>
                <Col lg={true}>
                    <strong><FaQuestionCircle/> How did you get into massage therapy?</strong><br/><br/>

                    <p className="font-weight-lighter font-italic">
                        "My grandmother used to tell me that I had 'healing hands'. Throughout my life, I have come to
                        realize that this is a gift, I am making a difference in the lives of those who may get in :TOUCH:"
                    </p>

                    <strong><FaQuestionCircle/> Do you enjoy what you do?</strong><br/><br/>

                    <p className="font-weight-lighter font-italic">
                        "I love what I do. I get to play an active role in helping others improve their wellness. My job
                        is
                        very rewarding."
                    </p>

                    <strong><FaQuestionCircle/> When did you get licensed?</strong><br/><br/>

                    <p className="font-weight-lighter font-italic">
                        "I earned my massage license in 2013 and have been practicing my own business in Portland
                        since 2014."
                    </p>

                </Col>

                <Col lg={true}>
                    <div className="tealights"> </div><br/>
                </Col>
            </Row>
        </Container>

    </Layout>
)

export default SecondPage
